//Font size
//Typography
html,
body {
  font-size: 14px !important;
}

h1 {
  font-size: 1.602rem;
}

h2 {
  font-size: 1.424rem;
}

h3 {
  font-size: 1.266rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 0.889rem;
}

h6 {
  font-size: 0.79rem;
}

.display-1 {
  font-size: 5.656rem;
}

.display-2 {
  font-size: 4.875rem;
}

.display-3 {
  font-size: 4.875rem;
}

.display-4 {
  font-size: 2.75rem;
}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(sm) {
  html,
  body {
    font-size: 14px !important;
  }
}

// Medium devices (tablets, 48em and up)
@include media-breakpoint-up(md) {
  html,
  body {
    font-size: 14px !important;
  }
  h1 {
    font-size: 2.441em;
  }
  h2 {
    font-size: 1.953em;
  }
  h3 {
    font-size: 1.563em;
  }
  h4 {
    font-size: 1.25em;
  }
  .display-1 {
    font-size: 6em;
  }
  .display-2 {
    font-size: 5.5em;
  }
  .display-3 {
    font-size: 4.5em;
  }
  .display-4 {
    font-size: 3.5em;
  }
}

// Large devices (desktops, 62em and up)
@include media-breakpoint-up(lg) {
  html,
  body {
    font-size: 16px !important;
  }
}

// Extra large devices (large desktops, 75em and up)
@include media-breakpoint-up(xl) {
  html,
  body {
    font-size: 18px !important;
  }
}

// Font weights
$font-weights: (
  "black": 900,
  "extra-bold": 800,
  "bold": 700,
  "semi-bold": 600,
  "medium": 500,
  "normal": 400,
  "light": 300,
  "extra-light": 200,
  "thin": 100,
);

@each $name, $val in $font-weights {
  .font-weight-#{$name} {
    font-weight: $val;
  }
}

$font-weight-semi-bold: 600;

body {
  font-family: $mdb-font-family;
  font-weight: $font-weight-normal;
}

h1 {
  font-family: $title-font-family;
  font-weight: $font-weight-semi-bold;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

.card .card-body h1,
.card .card-body h2,
.card .card-body h3,
.card .card-body h4,
.card .card-body h5,
.card .card-body h6 {
  font-weight: 600;
}

.hover-link:hover {
  color: $primary-color !important;
}

.text-decoration-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.text-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.small,
small {
  font-size: 85%;
  font-weight: 400;
}
