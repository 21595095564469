@font-face {
  font-family: "Bree Serif";
  src: url("https://use.typekit.net/af/53249f/00000000000000000001441a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/53249f/00000000000000000001441a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("woff"),
    url("https://use.typekit.net/af/53249f/00000000000000000001441a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}
