// Outline button
@mixin make-outline-button($name, $color) {
  .btn-outline-#{$name} {
    color: $color !important;
    background-color: transparent !important;
    border: 1px solid $color !important;

    &:hover,
    &:focus,
    &:active,
    &:active:focus,
    &.active {
      color: $color !important;
      background-color: transparent !important;
      border-color: $color !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
      background-color: transparent !important;
      border-color: $color !important;
      box-shadow: $z-depth-1-half;
    }

    &:not([disabled]):not(.disabled):active:focus,
    &:not([disabled]):not(.disabled).active:focus,
    .show > &.dropdown-toggle:focus {
      box-shadow: $z-depth-1-half;
    }
  }
}

@include make-outline-button("light-grey", $light-grey);

//Sizing
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $prop, $abbrev in (width: w, height: h) {
      @each $size, $length in $sizes {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
      }
    }
  }
}

// Colors
@include bg-variant(".bg-alabaster", $alabaster);
@include text-emphasis-variant(".light-blue-text", $light-blue);
@include text-emphasis-variant(".silver-text", $silver);
