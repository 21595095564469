@import "~mdbreact/dist/scss/core/bootstrap/variables";

// Base
:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  line-height: $line-height-base;
}

footer {
  width: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.view.full {
  flex: 1 0 auto;
}

// Navbar
.navbar-toggler {
  padding: 0;
  font-size: 1rem;
}

// Inputs
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: none !important;
  appearance: none !important;
}

.form-control,
.was-validated .form-control {
  box-shadow: none;

  &:focus,
  &:invalid:focus,
  &:valid:focus {
    box-shadow: $z-depth-1;
  }
}

@include media-breakpoint-up(sm) {
  .input-group > .input-subgroup:not(:first-child) > .custom-select,
  .input-group > .input-subgroup:not(:first-child) > .form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group > .input-subgroup:not(:last-child) > .custom-select,
  .input-group > .input-subgroup:not(:last-child) > .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// Checkbox
.custom-control {
  min-height: 1.9375rem;
  padding-left: 2.5rem;
}

.custom-checkbox .custom-control-label {
  padding-top: 0.45rem;
  color: $black !important;
}

.custom-checkbox .custom-control-label::before {
  width: 1.9375rem;
  height: 1.9375rem;
  top: 0;
  left: -2.5rem;
  border-radius: 100%;
}

.custom-checkbox .custom-control-label::after {
  width: 1.9375rem;
  height: 1.9375rem;
  top: 0;
  left: -2.5rem;
}

.custom-control-input ~ .custom-control-label::before {
  border-color: $grey-base !important;
  background-color: $white !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: $primary-color !important;
  border-color: $primary-color !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg' ml-update='aware'%3E%3Cpath d='M13.4422 1.5L5.8335 9.1087L2.375 5.6502' stroke='%230086E7' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

// Button
.btn[class*="btn-outline-"] {
  padding-left: $btn-outline-padding-x-basic;
  padding-right: $btn-outline-padding-x-basic;
}

.card.card-flat {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn.btn-flat {
  font-weight: 600;
  color: inherit;
  background-color: transparent !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@include media-breakpoint-up(md) {
  .btn-group.btn-rounded > .btn-group.btn-rounded:first-child > .btn,
  .btn-group,
  .btn-rounded > .btn:first-child:not(.dropdown-toggle) {
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem;
  }

  .btn-group.btn-rounded > .btn-group.btn-rounded:last-child > .btn,
  .btn-group,
  .btn-rounded > .btn:last-child:not(.dropdown-toggle) {
    border-top-right-radius: 10rem;
    border-bottom-right-radius: 10rem;
  }
}

.btn.btn-outline-primary {
  border-width: 1px !important;
}

// Alert
.alert-danger {
  color: $black;
  background-color: $danger-alert-bg;
  border-color: $danger-alert-bg;
}

.alert-success {
  color: $black;
  background-color: $success-alert-bg;
  border-color: $success-alert-bg;
}

// Tabs
.navbar.navbar-dark {
  .navbar-nav {
    .nav-item-tab {
      display: flex;
      align-items: center;
      text-align: center;
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
      padding-right: $grid-gutter-width / 2;
      padding-left: $grid-gutter-width / 2;
      font-weight: 600;

      .nav-link {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $slight-blue;
        padding-bottom: 0.75rem;

        &:focus {
          border: 0;
        }
      }

      @media (min-width: 48em) {
        .nav-link {
          padding-bottom: 1.5rem;
        }
      }

      &.active {
        .nav-link {
          color: $white;
          background: $transparent;

          &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0.45rem;
            background: $light-blue;
            border-radius: $border-radius-base $border-radius-base 0 0;
          }

          @media (min-width: 48em) {
            &:after {
              height: 0.55rem;
            }
          }
        }
      }
    }
  }
}

.navbar {
  .navbar-collapse {
    .navbar-nav {
      .nav-item {
        .nav-link {
          font-size: $font-size-lg;

          i {
            color: $silver;
            transition: $transition-base;
          }

          &:hover,
          &:active,
          &:focus,
          &:hover i,
          &:active i,
          &:focus i {
            color: $primary-color;
          }
        }
      }
    }
  }

  .navbar-toggler.collapsed {
    .navbar-toggler-icon {
      background-image: $icon-close;
    }
  }
}

// Avatar
.avatar {
  width: 2.85rem;
  height: 2.85rem;
  overflow: hidden;
  border-radius: 50%;

  &.lg {
    width: 5.56rem;
    height: 5.56rem;
  }
}

// Dropdown
.dropdown {
  .dropdown-custom {
    padding: 0;
    margin-top: -0.375rem;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    border-color: $light-blue;

    .dropdown-item {
      padding: $btn-padding-y-basic $btn-outline-padding-x-basic;
      font-size: $btn-font-size-basic;
      border-bottom: 1px solid $light-blue;

      &:last-child {
        border-bottom: none;
      }

      &:hover:not(.active),
      &:active:not(.active) {
        color: $primary-color;
        background-color: $transparent;
      }

      &.active {
        background-color: $primary-color;
        &:last-child {
          border-radius: 0 0 $border-radius-base $border-radius-base;
        }
      }
    }
  }

  .dropdown-toggle:after {
    background-image: $icon-dropdown;
    mask-repeat: no-repeat;
    border: none;
    width: 15px;
    height: 9px;
    margin-left: 0.5rem;
    padding: 4px;
  }
}

// List group
.list-group-flush {
  .list-group-item {
    color: $black-base;

    i {
      color: $silver;
      transition: $transition-base;
    }

    &:hover,
    &:focus,
    &:active,
    &:hover i,
    &:focus i,
    &:active i {
      color: $primary-color;
    }
  }
}

// Modal
.modal-header .close {
  display: none;
}

// Icons
.fa-cog::before {
  mask: $icon-cog;
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: currentColor;
}

.fa-sign-out-alt::before {
  mask: $icon-sign-out;
  mask-size: contain;
  mask-repeat: no-repeat;
  background-color: currentColor;
}

.fa-angle-down::before {
  mask: $icon-angle-down;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: transparent;
}

.fa-angle-up::before {
  mask: $icon-angle-up;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: transparent;
}

.fa-arrow-right::before {
  mask: $icon-arrow-right;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: transparent;
}

.fa-download::before {
  mask: $icon-download;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  background-color: currentColor;
}

// Skeleton
span.react-loading-skeleton {
  line-height: 1.1;
}

// Border radius
.rounded-top-left {
  border-top-left-radius: $border-radius-base !important;
}

.rounded-top-right {
  border-top-right-radius: $border-radius-base !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: $border-radius-base !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: $border-radius-base !important;
}

.card {
  border-radius: $md-card-border-radius;
}

// Plan
.benefit {
  > div {
    > b {
      display: inline-block;
      margin-bottom: $label-margin-bottom;
    }
  }

  p {
    color: $silver;
    margin: 0;
  }
}
