#temp-forum-banner {
  background: linear-gradient(271.75deg, #2f68ca 1.24%, #394c7f 135.92%);
}

.temp-btm-close {
  float: right;
  border: 0;
  background: transparent;
}

.temp-popup-content {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: #fff;
}
.temp-popup {
  max-width: 75rem;
  margin: auto;
  padding: 0.5rem 1rem;
}
.temp-popup-content--high {
  color: #fdd090;
}
.cro_forum--link {
  color: #fff;
}
@media (min-width: 768px) {
  .temp-popup-content {
    font-size: 22px;
  }
}
.forum-in-modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.forum-in-modal-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.forum-in-modal-close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.forum-in-modal-close:hover,
.forum-in-modal-close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.epic .forum-in-modal--companion {
  display: none;
}
.forum-in-modal--north {
  display: none;
}
.epic .forum-in-modal--north {
  display: block;
}

.header-container {
  margin-left: "auto";
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 0px;
}
